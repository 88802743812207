import FullModal from "../FullModal";
import SignDocuments from "./SignDocuments";

interface SignDocumentsModalProps {
  visible: boolean;
  blur?: boolean;
  closeModal?: () => void;
  user: User;
  participant?: Participant;
  closeOnCompletion?: boolean;
  documents: UserDocument[];
}

const SignDocumentsModal: React.FC<SignDocumentsModalProps> = (props) => {
  const { visible, closeModal, blur, ...other } = props;
  return (
    <FullModal onClose={closeModal} visible={visible} isDocuments blur={blur}>
      <SignDocuments {...other} />
    </FullModal>
  );
};

export default SignDocumentsModal;
