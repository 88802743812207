import React from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Button, EmailController } from "@pushpress/shared-components";

import PageContainer from "../../../components/PageContainer";
import PageTitle from "../../../components/PageTitle";

interface EmailStepProps {
  loading: boolean;
  email?: string;
  onContinue: (email: string) => void;
}

interface FormValues {
  email: string;
}

const EmailStep = (props: EmailStepProps) => {
  const { loading, email, onContinue } = props;

  const { t } = useTranslation("common");

  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      email,
    },
  });

  const handleContinue = (values: FormValues) => {
    onContinue(values.email);
  };

  return (
    <PageContainer.RightPane>
      <PageTitle title={t("login.emailTitle")} theme="light" />
      <form onSubmit={handleSubmit(handleContinue)}>
        <EmailController
          control={control}
          name="email"
          required
          label={t("login.yourEmail")}
        />
        <Button
          fullWidth
          buttonType="primary"
          size="large"
          text={t("createAccount.continue")}
          className="mt-4"
          type="submit"
          loading={loading}
        />
      </form>
    </PageContainer.RightPane>
  );
};

export default EmailStep;
