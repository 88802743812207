import React from "react";
import { useAppContext } from "../../../contexts/AppContext";
import PageContainer from "../../../components/PageContainer";
import TransactionSummary from "../../../components/TransactionSummary";
import PlanSummary from "../../../components/PlanSummary";
import EventSummary from "../../../components/EventSummary";
import AuthenticationContent from "./AuthenticationContent";

const Authentication: React.FC = () => {
  const { landingPagesType, setUserUuid } = useAppContext();

  const handleSuccess = async (userUuid: string) => {
    // set user uuid to load the user and go to the first page
    setUserUuid(userUuid);
  };

  return (
    <PageContainer>
      <PageContainer.LeftPane>
        {landingPagesType === "appointment" && <TransactionSummary />}
        {landingPagesType === "plan" && <PlanSummary />}
        {landingPagesType === "event" && <EventSummary />}
      </PageContainer.LeftPane>
      <AuthenticationContent onSuccess={handleSuccess} />
    </PageContainer>
  );
};

export default Authentication;
