import React from "react";
import { multiStyles } from "../../utils";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  Icons,
  Modal,
  Text,
  Toast,
  useDevice,
  Drawer,
} from "@pushpress/shared-components";
import moduleStyles from "./ConfirmDialog.module.scss";

export interface ConfirmDialogProps {
  visible: boolean;
  title?: string;
  message?: string;
  confirmText?: string;
  onClose: () => void;
  onConfirm?: () => void;
}

const S = multiStyles(moduleStyles);

const ConfirmDialog: React.FC<ConfirmDialogProps> = (props) => {
  const { visible, onClose } = props;

  const { isMobile } = useDevice();

  return isMobile ? (
    <Drawer
      anchor="bottom"
      visible={visible}
      className={S("drawer")}
      closeModal={onClose}
    >
      <div className={S("drawerContent")}>
        <ConfirmDialogContent {...props} />
      </div>
    </Drawer>
  ) : (
    <Modal visible={visible} closeModal={onClose}>
      <div className={S("modalContent")}>
        <ConfirmDialogContent {...props} />
      </div>
    </Modal>
  );
};

const ConfirmDialogContent = (props: ConfirmDialogProps) => {
  const { title, confirmText, message, onClose, onConfirm } = props;

  return (
    <>
      <div className={S("content")}>
        <Grid container>
          <Grid item xs={12} container justifyContent="flex-end">
            <IconButton icon={Icons.X} onClick={onClose} />
          </Grid>
        </Grid>
        <Text
          component="p"
          variant="heading-4"
          color="wolf-800"
          className="mb-3"
        >
          {title}
        </Text>
        {message && <Toast message={message} icon={Icons.Information} />}
      </div>
      <Divider />
      <div className={S("action")}>
        <Button fullWidth text={confirmText} size="large" onClick={onConfirm} />
      </div>
    </>
  );
};

export default ConfirmDialog;
