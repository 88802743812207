import React from "react";
import { Grid, useDevice } from "@pushpress/shared-components";
import { useAppContext } from "../../contexts/AppContext";
import { getMobileOS } from "../../utils";
import { useAnalytics } from "../AnalyticsProvider";
import appStoreSvg from "../../assets/app-store.svg";
import googlePlaySvg from "../../assets/google-play.svg";
import styles from "./DownloadMemberApp.module.scss";

const DownloadMemberApp = () => {
  const { isMobile } = useDevice();
  const { source, appleStoreLink, googleStoreLink } = useAppContext();

  if (source === "staffapp" || source === "memberapp") {
    return null;
  }

  return (
    <div>
      {isMobile ? (
        <MobileAppStoreButton />
      ) : (
        <Grid container spacing={2}>
          {appleStoreLink && (
            <Grid item>
              <AppStoreButton />
            </Grid>
          )}
          {googleStoreLink && (
            <Grid item>
              <GooglePlayButton />
            </Grid>
          )}
        </Grid>
      )}
    </div>
  );
};

export const MobileAppStoreButton = ({
  appleLink,
  googleLink,
}: {
  appleLink?: string;
  googleLink?: string;
}) => {
  if (getMobileOS() === "ios") {
    return <AppStoreButton fallbackLink={appleLink} />;
  }
  if (getMobileOS() === "android") {
    return <GooglePlayButton fallbackLink={googleLink} />;
  }
  return null;
};

const AppStoreButton = ({ fallbackLink }: { fallbackLink?: string }) => {
  const { appleStoreLink } = useAppContext();
  const link = appleStoreLink ?? fallbackLink;
  const analytics = useAnalytics();

  const trackDownloadAppEvent = () => {
    analytics.trackEvent("download_app_clicked", {}, true);
  };

  const handleClick = () => {
    trackDownloadAppEvent();
    window.open(link!, "_blank");
  };

  if (!link) {
    return null;
  }

  return (
    <button type="button" onClick={handleClick} className={styles.button}>
      <img src={appStoreSvg.src} alt="App store" />
    </button>
  );
};

const GooglePlayButton = ({ fallbackLink }: { fallbackLink?: string }) => {
  const { googleStoreLink } = useAppContext();
  const link = googleStoreLink ?? fallbackLink;
  const analytics = useAnalytics();

  const trackDownloadAppEvent = () => {
    analytics.trackEvent("download_app_clicked", {}, true);
  };

  const handleClick = () => {
    trackDownloadAppEvent();
    window.open(link!, "_blank");
  };

  if (!link) {
    return null;
  }

  return (
    <button type="button" onClick={handleClick} className={styles.button}>
      <img src={googlePlaySvg.src} alt="Google play" />
    </button>
  );
};

export default DownloadMemberApp;
