import React from "react";
import { Divider, Modal } from "@pushpress/shared-components";
import EventDetails from "../EventDetails";
import DescList from "../DescList";
import { useTranslation } from "react-i18next";
import { useFormatters } from "../../utils/hooks";
import { useEventContext } from "../../contexts/EventContext";
import { formatInTimeZone } from "date-fns-tz";
import { toFixedConditional } from "../../utils/priceUtils";

interface EventModalProps {
  visible: boolean;
  onClose: VoidFunction;
}

const EventModal = (props: EventModalProps) => {
  const { visible, onClose } = props;
  const { t } = useTranslation("events");
  const { event, endRegistrationDate, transactionFee } = useEventContext();
  const { formatCurrency, formatPercent } = useFormatters();
  const registrationCloses = endRegistrationDate
    ? formatInTimeZone(
        new Date(endRegistrationDate),
        "UTC",
        "MMMM d, yyyy h:mma",
      )
    : undefined;

  return (
    <Modal visible={visible} closeModal={onClose}>
      <Modal.Card>
        <Modal.Header title=" " onClose={onClose} />
        <Modal.Content>
          <EventDetails event={event} theme="light" />
          <Divider vPadding={24} />
          <DescList variant="body-lg" theme="light" gap={16}>
            {event.attendanceCap > 0 && (
              <DescList.Item term={t("summary.spotsRemaining")}>
                {event.spotsAvailable}
              </DescList.Item>
            )}
            {registrationCloses && (
              <DescList.Item term={t("summary.registrationCloses")}>
                {registrationCloses}
              </DescList.Item>
            )}
            <DescList.Item term={t("summary.price")}>
              {formatCurrency(event.price)}
            </DescList.Item>
            {event.tax?.amount && (
              <DescList.Item term={t("summary.tax", { name: event.tax.name })}>
                {formatPercent(event.tax.amount)}
              </DescList.Item>
            )}
            {transactionFee && (
              <DescList.Item
                term={`${transactionFee?.description} (${toFixedConditional(
                  Number(transactionFee?.rate) * 100,
                )}%)`}
              >
                {formatCurrency(transactionFee.fee || 0)}
              </DescList.Item>
            )}
          </DescList>
        </Modal.Content>
      </Modal.Card>
    </Modal>
  );
};

export default EventModal;
