import React from "react";
import { Grid } from "@pushpress/shared-components";
import logo from "../../../public/images/pp-logo-icon-reverse.svg";
import Text from "../Text";
import { Trans, useTranslation } from "react-i18next";

interface ResetPasswordSummaryProps {
  email: string;
}

const ResetPasswordSummary = (props: ResetPasswordSummaryProps) => {
  const { email } = props;

  const { t } = useTranslation("common");

  return (
    <div>
      <Grid container>
        <Grid item xs={1}>
          <img src={logo.src} alt="PushPress logo" height={30} />
        </Grid>
        <Grid item xs={11}>
          <Text
            variant="heading-4"
            component="p"
            color="white"
            className="ml-2"
          >
            <Trans
              t={t}
              i18nKey="resetPassword.summaryTitle"
              components={{
                bold: <strong />,
              }}
            />
          </Text>
        </Grid>
      </Grid>
      {email && (
        <Text variant="body-lg" component="p" color="white" className="mt-1">
          {t("resetPassword.summaryMessage", { email })}
        </Text>
      )}
    </div>
  );
};

export default ResetPasswordSummary;
