import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Divider, Text, useToggle } from "@pushpress/shared-components";
import { useFormatters } from "../../utils/hooks";
import { useEventContext } from "../../contexts/EventContext";
import BrandHeading from "../BrandHeading";
import EventDetails from "../EventDetails";
import EventModal from "../EventModal";

interface EventSummaryProps {
  confirmation?: boolean;
}

const EventSummary = (props: EventSummaryProps) => {
  const { confirmation } = props;

  const { t } = useTranslation("events");

  const { event, amountDue, isFree } = useEventContext();
  const { formatCurrency } = useFormatters();
  const [moreInfoVisible, moreInfoActions] = useToggle();

  if (!event) {
    return null;
  }

  return (
    <>
      <div>
        <BrandHeading />
        {confirmation ? (
          <Text variant="heading-1" color="white" className="mt-4">
            {t("summary.confirmed")}
          </Text>
        ) : (
          <>
            <Text
              variant="body-lg"
              component="p"
              color="white"
              className="mt-4"
            >
              {t("summary.dueToday")}
            </Text>
            <Text variant="heading-1" component="p" color="white">
              {isFree ? t("summary.free") : formatCurrency(amountDue)}
            </Text>
          </>
        )}
        <Divider vPadding={24} />
        <EventDetails event={event} />
        <Button
          type="button"
          buttonType="secondary"
          size="small"
          text={t("summary.moreInfo")}
          className="mt-3"
          onClick={moreInfoActions.on}
        />
      </div>
      <EventModal visible={moreInfoVisible} onClose={moreInfoActions.off} />
    </>
  );
};

export default EventSummary;
