import { FC, ReactNode } from "react";
import {
  IconButton,
  Modal,
  PanelHeader,
  Icons,
  ModalProps,
} from "@pushpress/shared-components";
import { multiStyles } from "../../utils";
import moduleStyles from "./PanelModal.module.scss";

const S = multiStyles(moduleStyles);

export interface PanelModalProps extends ModalProps {
  title: string;
  actions?: ReactNode;
}

const PanelModal: FC<PanelModalProps> = (props) => {
  const { visible, closeModal, title, children, actions } = props;

  const closeButton = (
    <IconButton size={24} icon={Icons.X} onClick={() => closeModal?.()} />
  );

  return (
    <Modal visible={visible} closeModal={closeModal}>
      <div className={S("root")}>
        <div className={S("header")}>
          <PanelHeader title={title}>
            <PanelHeader.ActionMenu>
              <>{actions || closeButton}</>
            </PanelHeader.ActionMenu>
          </PanelHeader>
        </div>
        <div className={S("content")}>{children}</div>
      </div>
    </Modal>
  );
};

export default PanelModal;
