import React, { FC } from "react";
import moduleStyles from "./SwitchButtonGroup.module.scss";
import { joinClasses, multiStyles } from "../../utils";

const S = multiStyles(moduleStyles);

const SwitchButtonGroup: FC<React.HTMLAttributes<HTMLDivElement>> = (props) => {
  const { children, className, ...other } = props;

  const rootClassName = joinClasses(S("root"), className);

  return (
    <div {...other} className={rootClassName}>
      {children}
    </div>
  );
};

export default SwitchButtonGroup;
