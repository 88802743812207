import { createContext, useContext } from "react";
import {
  DiscountFragment,
  EventFragment,
  EventInvoicePreviewFragment,
} from "../graphql/graphql-generated.types";

export interface EventContextState {
  event: EventFragment;
  invoicePreview?: EventInvoicePreviewFragment;
  transactionFee?: NonNullable<EventInvoicePreviewFragment["fees"]>[number];
  amountDue: number;
  startRegistrationDate?: number;
  endRegistrationDate?: number;
  eventMessage?: string;
  isFree?: boolean;
  isLinkedAccount: boolean;
  participant?: Participant;
  participantType?: ParticipantType;
  participantTypeVisible: boolean;
  discount?: DiscountFragment;
  paying: boolean;
  paymentMethodId?: string;
  paymentMethodErrors: {
    [key: string]: boolean;
  };
  documentsSuccessVisible: boolean;
  documentsSuccessActions: UseToggle;
  goToScreen: (screen: EventScreen) => void;
  setParticipantType: (type: ParticipantType) => void;
  setParticipant: (participant?: Participant) => void;
  addNewParticipant: () => void;
  setDiscount: (discount?: DiscountFragment) => void;
  setPaymentMethodId: (id: string) => void;
  purchaseEvent: (
    billing: string,
    paymentType: PaymentMethodType | "free",
  ) => void;
}

const EventContext = createContext<EventContextState | undefined>(undefined);

export const useEventContext = () => {
  const contextValue = useContext(EventContext);
  if (!contextValue) {
    throw new Error("useEventContext must be used inside EventContext");
  }
  return contextValue;
};

export default EventContext;
