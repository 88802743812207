import React from "react";
import {
  Icons,
  Grid,
  Text,
  formatRange,
  formatTimeRange,
} from "@pushpress/shared-components";
import { EventFragment } from "../../graphql/graphql-generated.types";
import { joinStrings } from "../../utils/stringUtils";

interface EventDetailsProps {
  event: EventFragment;
  theme?: "light" | "dark";
}

const EventDetails = (props: EventDetailsProps) => {
  const { event, theme = "dark" } = props;

  const eventDate = formatRange(
    new Date(event.startDatetime),
    new Date(event.endDatetime),
    "UTC",
  );
  const eventTime = formatTimeRange(
    new Date(event.startDatetime),
    new Date(event.endDatetime),
    "UTC",
  );
  const textColor = theme === "dark" ? "white" : "wolf-800";
  const iconColor = theme === "dark" ? "white" : "#60717D";

  return (
    <div>
      <Text variant="heading-4" component="h2" color={textColor}>
        {event.title}
      </Text>
      <Grid container columnSpacing={2} className="mt-1">
        <Grid item xs={1} container alignItems="center">
          <Icons.Clock color={iconColor} width={20} height={20} />
        </Grid>
        <Grid item xs={11}>
          <Text component="div" variant="body-lg" color={textColor}>
            {eventDate}
          </Text>
          <Text component="div" variant="body-lg" color={textColor}>
            {eventTime}
          </Text>
        </Grid>
        {event.location && (
          <>
            <Grid item xs={1} container alignItems="center">
              <Icons.Location color={iconColor} width={20} height={20} />
            </Grid>
            <Grid item xs={11}>
              {event.location.link ? (
                <Text
                  component="a"
                  href={event.location.link}
                  variant="body-lg"
                  color={textColor}
                  target="_blank"
                >
                  {event.location.name}
                </Text>
              ) : (
                <Text component="p" variant="body-lg" color={textColor}>
                  {event.location.name}
                </Text>
              )}
            </Grid>
          </>
        )}
        {event.mainCoach && (
          <>
            <Grid item xs={1} container alignItems="center">
              <Icons.User color={iconColor} width={20} height={20} />
            </Grid>
            <Grid item xs={11}>
              <Text variant="body-lg" color={textColor}>
                {joinStrings(
                  event.mainCoach.firstName,
                  event.mainCoach.lastName,
                )}
              </Text>
            </Grid>
          </>
        )}
      </Grid>
      <Text
        variant="body-lg"
        component="p"
        color={textColor}
        className="mt-3"
        whiteSpace="pre-line"
      >
        {event.description}
      </Text>
    </div>
  );
};

export default EventDetails;
