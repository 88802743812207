import styles from "./NotFoundPage.module.scss";

const NotFoundPage = () => {
  return (
    <div className={styles.root}>
      <h1 className={styles.title}>404</h1>
      <p className={styles.message}>Page not found</p>
    </div>
  );
};

export default NotFoundPage;
