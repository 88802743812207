import { useMemo } from "react";
import { Text } from "@pushpress/shared-components";
import { useAppContext } from "../../contexts/AppContext";
import { getSignDocumentsUrl, multiStyles } from "../../utils";
import { useToggle } from "../../utils/hooks";
import IframeView from "../IframeView";
import moduleStyles from "./SignDocuments.module.scss";

interface SignDocumentsProps {
  user: User;
  participant?: Participant;
  closeOnCompletion?: boolean;
  documents: UserDocument[];
}

const S = multiStyles(moduleStyles);

const SignDocuments: React.FC<SignDocumentsProps> = (props) => {
  const { user, documents = [], closeOnCompletion } = props;
  const [loading, loadingToggle] = useToggle(true);
  const { subdomain } = useAppContext();

  const unsignedDocuments = useMemo(() => {
    return documents.filter((doc) => doc.is_signed === "0");
  }, [documents]);

  const hasUnsignedDocuments = unsignedDocuments.length > 0;

  if (closeOnCompletion && !hasUnsignedDocuments) {
    return (
      <div className={S("container")}>
        <Text variant="heading-3">Documents signed!</Text>
        <Text variant="body-lg" className="mt-1">
          Please close this window to continue checking in.
        </Text>
      </div>
    );
  }

  const iframeSrc = getSignDocumentsUrl(subdomain, user.userUuid);

  return (
    <IframeView src={iframeSrc} loading={loading} onLoad={loadingToggle.off} />
  );
};

export default SignDocuments;
