import React from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  PasswordController,
  Text,
  useToggle,
} from "@pushpress/shared-components";
import { useTranslation } from "react-i18next";
import { extractError } from "../../utils";
import { useAppContext } from "../../contexts/AppContext";
import { useToast } from "../ToastProvider";
import DownloadMemberApp from "../DownloadMemberApp";
import { useUpdatePasswordMutation } from "./AccessMemberApp-generated.hooks";

interface FormValues {
  password: string;
}

const AccessMemberApp = () => {
  const { t } = useTranslation("common");

  const { userHasPassword, refetchUserInfo } = useAppContext();

  const { setErrorToast, setSuccessToast } = useToast();

  const [loading, loadingActions] = useToggle(false);

  const [updatePassword] = useUpdatePasswordMutation();

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<FormValues>({
    mode: "onTouched",
  });

  const handleCreatePassword = async (values: FormValues) => {
    loadingActions.on();
    try {
      const { data } = await updatePassword({
        variables: {
          input: {
            newPassword: values.password,
            clientUuid: null,
            email: null,
            currentPassword: null,
            code: null,
          },
        },
      });
      await refetchUserInfo();
      if (data) {
        setSuccessToast({ message: t("accessMemberApp.passwordSuccess") });
      }
    } catch (err: unknown) {
      setErrorToast({ message: extractError(err) });
    } finally {
      loadingActions.off();
    }
  };

  return (
    <div>
      <Text
        component="h2"
        variant="heading-4"
        color="wolf-800"
        className="mb-2"
      >
        {t("accessMemberApp.title")}
      </Text>
      <Text variant="body-lg" color="wolf-600" className="mb-3">
        {t("accessMemberApp.description")}
      </Text>
      {userHasPassword ? (
        <DownloadMemberApp />
      ) : (
        <form onSubmit={handleSubmit(handleCreatePassword)}>
          <PasswordController
            control={control}
            name="password"
            label={t("accessMemberApp.passwordLabel")}
            required
            helperText={t("accessMemberApp.passwordHelper")}
            className="mb-2"
            size="large"
            minLength={6}
          />
          <Button
            text={t("accessMemberApp.createPassword")}
            type="submit"
            size="large"
            disabled={!isValid}
            fullWidth
            loading={loading}
          />
        </form>
      )}
    </div>
  );
};

export default AccessMemberApp;
