import { Loader } from "@pushpress/shared-components";
import { multiStyles } from "../../utils";
import moduleStyles from "./IframeView.module.scss";

interface IframeViewProps {
  src: string;
  loading?: boolean;
  onLoad?: VoidFunction;
}

const S = multiStyles(moduleStyles);

const IframeView = (props: IframeViewProps) => {
  const { src, loading, onLoad } = props;

  return (
    <div className={S("root")}>
      {loading && (
        <div className={S("loader")}>
          <Loader size={48} />
        </div>
      )}
      <iframe
        src={typeof window !== "undefined" ? src : "about:blank"}
        onLoad={onLoad}
      />
    </div>
  );
};

export default IframeView;
