import React from "react";
import { useTranslation } from "react-i18next";
import { Divider, Text, Icons } from "@pushpress/shared-components";
import { usePlanContext } from "../../contexts/PlanContext";
import { getFriendlyTaxName } from "../../utils";
import { useFormatters } from "../../utils/hooks";
import { formatRange } from "../../utils/dateUtils";
import { removeTimezone } from "../../utils/locale";
import { PlanFragment } from "../../graphql/graphql-generated.types";
import BrandHeading from "../BrandHeading";
import DescList from "../DescList";
import IconCard from "../IconCard";
import MoreInfoButton from "../MoreInfoButton";
import { toFixedConditional } from "../../utils/priceUtils";

const PlanSummary = () => {
  const { t } = useTranslation("plan");
  const { formatCurrency, formatDate, formatNumber } = useFormatters();
  const {
    plan,
    planDiscount,
    amountDue,
    amountTax,
    isFree,
    discount,
    calendarItem,
    startDate,
    transactionFee,
  } = usePlanContext();

  if (!plan) {
    return null;
  }

  const setupFeeValue = Number(plan.setupFee) || 0;
  const isDiscountPercentage =
    planDiscount?.discountType.discountTypeCode === "%";
  const discountTerm = `${t("summary.discount")}${
    isDiscountPercentage ? ` (${formatNumber(planDiscount.amount)}%)` : ""
  }`;
  const taxTerm = getFriendlyTaxName(plan.tax);
  const intervalTypeText = plan.intervalType
    ? t(`summary.billingPeriod${plan.intervalType as "D" | "W" | "M" | "Y"}`, {
        count: Number(plan.interval),
      })
    : t("summary.credit");

  return (
    <>
      <div>
        <BrandHeading marginBottom={40} />
        <Text variant="body-lg" component="p" color="white">
          {t("summary.amountDue")}
        </Text>
        <Text variant="heading-1" component="p" color="white">
          {isFree ? t("summary.free") : formatCurrency(amountDue!)}
        </Text>
        <Divider vPadding={24} />
        {planDiscount?.description && (
          <IconCard icon={Icons.Discount} className="mb-3">
            {planDiscount.description}
          </IconCard>
        )}
        <Text variant="heading-4" component="h2" color="white">
          {plan.name}
        </Text>
        <DescList variant="body-lg" theme="dark" className="mt-3">
          <DescList.Item term={t("summary.price")}>
            {formatCurrency(Number(plan.amount))}
          </DescList.Item>
          {setupFeeValue > 0 && (
            <DescList.Item term={t("summary.setupFee")}>
              {formatCurrency(setupFeeValue)}
            </DescList.Item>
          )}
          {discount > 0 && (
            <DescList.Item term={discountTerm}>
              {`-${formatCurrency(discount)}`}
            </DescList.Item>
          )}
          {plan.tax && (
            <DescList.Item term={taxTerm}>
              {formatCurrency(amountTax)}
            </DescList.Item>
          )}
          {transactionFee && (
            <DescList.Item
              term={`${transactionFee?.description} (${toFixedConditional(
                Number(transactionFee?.rate) * 100,
              )}%)`}
            >
              {formatCurrency(transactionFee.fee || 0)}
            </DescList.Item>
          )}
          {plan.recurring !== "Non recurring" && (
            <DescList.Item term={t("summary.billing")}>
              {plan.recurring}
              {plan.occurrences ? ` (x${plan.occurrences})` : ""}
            </DescList.Item>
          )}
          {startDate && (
            <DescList.Item term={t("summary.startDate")}>
              {formatDate(removeTimezone(startDate))}
            </DescList.Item>
          )}
          {plan.recurring !== "Non recurring" && (
            <DescList.Item term={t("summary.classes")}>
              {plan.numberSessions}
            </DescList.Item>
          )}
          {plan.limitQuantity && (
            <DescList.Item term={t("summary.quantityRemaining")}>
              {plan.quantityAvailable > 0
                ? plan.quantityAvailable
                : t("summary.soldOut")}
            </DescList.Item>
          )}
          {plan.entitlements?.map((entitlement) => (
            <DescList.Item
              key={entitlement.entitlementUuid}
              term={getEntitlementDescription(entitlement)}
            >
              {`${entitlement.quantity} ${intervalTypeText}`}
            </DescList.Item>
          ))}
        </DescList>
        {plan.description && (
          <Text
            variant="body-lg"
            component="p"
            color="white"
            className="mt-3"
            whiteSpace="pre-line"
          >
            {plan.description}
          </Text>
        )}
        {plan.additionalInfo && (
          <MoreInfoButton
            text={t("summary.moreInfo")}
            className="mt-3"
            title={plan.name}
            html={plan.additionalInfo}
          />
        )}
        {calendarItem && (
          <>
            <Divider vPadding={24} />
            <Text variant="heading-5" color="white" className="mb-1">
              {calendarItem.title}
            </Text>
            <Text variant="body-lg" color="white" whiteSpace="pre">
              {formatRange(
                calendarItem.startDatetime,
                calendarItem.endDatetime,
                calendarItem.isAllDay,
              )}
            </Text>
          </>
        )}
      </div>
    </>
  );
};

type Entitlement = NonNullable<PlanFragment["entitlements"]>[number];

const getEntitlementDescription = (entitlement: Entitlement): string => {
  if (!entitlement.refEntitlement) {
    return "Empty entitlement";
  }
  const refType = entitlement.refEntitlement?.__typename;
  if (refType === "AppointmentType") {
    return entitlement.refEntitlement.name;
  }
  return "Invalid entitlement";
};

export default PlanSummary;
