import { FC } from "react";
import { joinClasses, multiStyles } from "../../utils";
import moduleStyles from "./Html.module.scss";

const S = multiStyles(moduleStyles);

interface HtmlProps {
  contentHtml?: string;
  className?: string;
}

const Html: FC<HtmlProps> = (props) => {
  const { contentHtml, className } = props;
  if (!contentHtml) {
    return null;
  }
  const rootClassName = joinClasses(S("root"), className);
  return (
    <div
      className={rootClassName}
      dangerouslySetInnerHTML={{ __html: contentHtml }}
    />
  );
};

export default Html;
