import React from "react";
import { useTranslation } from "react-i18next";
import { Text, Divider, Icons } from "@pushpress/shared-components";
import { useAppointmentContext } from "../../contexts/AppointmentContext";
import { multiStyles, secsToMins } from "../../utils";
import { useFormatters } from "../../utils/hooks";
import BrandHeading from "../BrandHeading";
import IconCard from "../IconCard";
import moduleStyles from "./TransactionSummary.module.scss";
import MoreInfoButton from "../MoreInfoButton";
import { toFixedConditional } from "../../utils/priceUtils";

const S = multiStyles(moduleStyles);

const TransactionSummary: React.FC = () => {
  const { t } = useTranslation("appt");
  const { formatCurrency, formatPercent } = useFormatters();
  const {
    appointmentPackage,
    appointmentType,
    amountDue,
    amountTax,
    percentageTax,
    isFree,
    discount,
    discountPercent,
    taxName,
    transactionFee,
  } = useAppointmentContext();

  if (!appointmentPackage || !appointmentType) {
    return null;
  }

  const isFreeFlag = appointmentPackage.isFree;
  const { numberOfSessions, description, packagePrice } = appointmentPackage;
  const totalDue = isFree ? t("summary.free") : formatCurrency(amountDue);
  const sessionDuration = secsToMins(appointmentType.duration!);
  const discountDescription = appointmentPackage.discount?.description;

  return (
    <>
      <BrandHeading marginBottom={40} />
      <div className={S("amount-overview")}>
        <p className={S("text")}>{t("summary.amountDue")}</p>
        <h1 className={S("amount")}>{totalDue}</h1>
      </div>
      <Divider color="#fff" />
      {discountDescription && (
        <IconCard icon={Icons.Discount} className="mt-5">
          {discountDescription}
        </IconCard>
      )}
      <h4 className={S("appointment-name")}>{appointmentPackage.name}</h4>
      <div className={S("transaction-breakdown")}>
        <div className={S("breakdown-line-item")}>
          <p className={S("text")}>{t("summary.price")}</p>
          <p className={S("text")}>
            {formatCurrency(isFreeFlag ? 0 : packagePrice)}
          </p>
        </div>
        {!isFreeFlag && !!discount && (
          <div className={S("breakdown-line-item")}>
            <p className={S("text")}>
              {t("summary.discount")}
              {discountPercent ? ` (${formatPercent(discountPercent)})` : ""}
            </p>
            <p className={S("text")}>-{formatCurrency(discount)}</p>
          </div>
        )}
        {!isFreeFlag && !!amountTax && (
          <div className={S("breakdown-line-item")}>
            <p className={S("text")}>
              {taxName} ({formatPercent(percentageTax)})
            </p>
            <p className={S("text")}>{formatCurrency(amountTax)}</p>
          </div>
        )}
        {transactionFee && (
          <div className={S("breakdown-line-item")}>
            <p className={S("text")}>
              {`${transactionFee?.description} (${toFixedConditional(
                Number(transactionFee?.rate) * 100,
              )}%)`}
            </p>
            <p className={S("text")}>
              {formatCurrency(transactionFee.fee || 0)}
            </p>
          </div>
        )}
        <div className={S("breakdown-line-item")}>
          <p className={S("text")}>{t("summary.numberOfSessions")}</p>
          <p className={S("text")}>{numberOfSessions}</p>
        </div>
        <div className={S("breakdown-line-item")}>
          <p className={S("text")}>{t("summary.sessionDuration")}</p>
          <p className={S("text")}>{sessionDuration} min</p>
        </div>
      </div>
      <Text variant="body-lg" whiteSpace="pre-line" color="white">
        {description}
      </Text>
      {appointmentPackage.longDescription && (
        <MoreInfoButton
          text={t("summary.moreInfo")}
          className="mt-3"
          title={appointmentPackage.name}
          html={appointmentPackage.longDescription}
        />
      )}
    </>
  );
};

export default TransactionSummary;
