import React, { ReactNode } from "react";
import { multiStyles } from "../../utils";
import {
  Modal,
  Text,
  useDevice,
  Drawer,
  Icons,
} from "@pushpress/shared-components";
import moduleStyles from "./SuccessDialog.module.scss";

export interface SuccessDialogProps {
  visible: boolean;
  title?: string;
  children?: ReactNode;
  actions?: ReactNode;
}

const S = multiStyles(moduleStyles);

const SuccessDialog: React.FC<SuccessDialogProps> = (props) => {
  const { visible } = props;

  const { isMobile } = useDevice();

  return isMobile ? (
    <Drawer anchor="bottom" visible={visible} className={S("drawer")}>
      <div className={S("drawerContent")}>
        <SuccessDialogContent {...props} />
      </div>
    </Drawer>
  ) : (
    <Modal visible={visible}>
      <div className={S("modalContent")}>
        <SuccessDialogContent {...props} />
      </div>
    </Modal>
  );
};

const SuccessDialogContent = (props: SuccessDialogProps) => {
  const { title, children, actions } = props;

  return (
    <>
      <div className={S("header")}>
        <div className={S("title")}>
          <Text variant="heading-4" color="wolf-800">
            {title}
          </Text>
        </div>
        <div className={S("icon")}>
          <Icons.Check />
        </div>
      </div>
      <div className={S("body")}>{children}</div>
      <div className={S("footer")}>{actions}</div>
    </>
  );
};

export default SuccessDialog;
