import { Trans, useTranslation } from "react-i18next";
import styles from "./TermsLabel.module.scss";
import { useAppContext } from "../../contexts/AppContext";

function TermsLabel() {
  const { t } = useTranslation("common");

  const { client } = useAppContext();

  return (
    <span className={styles.root}>
      <Trans
        t={t}
        i18nKey="termLabel.label"
        components={{
          privacyLink: (
            <a
              href={`https://${window.location.host}/open/policy/`}
              target="_blank"
              rel="noreferrer"
            />
          ),
        }}
        values={{ gymName: client?.company }}
      />
    </span>
  );
}

export default TermsLabel;
