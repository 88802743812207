export const calculateTotalPrice = (
  price: number,
  setupFee?: number,
  discountPercent?: boolean,
  discountValue?: number,
  tax?: number,
) => {
  let total = setupFee ? price + setupFee : price;
  if (discountValue) {
    if (discountPercent) {
      total -= price * (discountValue / 100);
    } else {
      total -= discountValue;
    }
  }
  if (tax) {
    total += total * (tax / 100);
  }
  if (total < 0) {
    return 0;
  }
  return total;
};

export const calculateTaxAmount = (
  price: number,
  setupFee?: number,
  discountPercent?: boolean,
  discountValue?: number,
  tax?: number,
) => {
  if (!tax) {
    return 0;
  }
  let total = setupFee ? price + setupFee : price;
  if (discountValue) {
    if (discountPercent) {
      total -= price * (discountValue / 100);
    } else {
      total -= discountValue;
    }
  }
  return total * (tax / 100);
};

export const calculateDiscount = (
  price: number,
  discountPercent?: boolean,
  discountValue?: number,
) => {
  if (discountValue) {
    if (discountPercent) {
      return price * (discountValue / 100);
    } else {
      return discountValue;
    }
  }
  return 0;
};

export function toFixedConditional(num: number): string {
  const fixedStr = num.toFixed(2);
  return fixedStr.endsWith(".00") ? fixedStr.slice(0, -3) : fixedStr;
}
