import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";
import { useToggle } from "../../utils/hooks";
import ConfirmDialog from "../ConfirmDialog";

type ConfirmContextValue = (o: ConfirmOptions) => void;

interface ConfirmProviderProps {
  children?: ReactNode;
}

interface ConfirmOptions {
  title: string;
  message?: string;
  confirmText: string;
  onConfirm: () => void;
}

const ConfirmContext = createContext<ConfirmContextValue | undefined>(
  undefined,
);

const ConfirmProvider = (props: ConfirmProviderProps) => {
  const { children } = props;

  const [visible, visibleActions] = useToggle(false);

  const [options, setOptions] = useState<ConfirmOptions>();

  const handleClose = useCallback(() => {
    setOptions(undefined);
    visibleActions.off();
  }, [visibleActions]);

  const handleConfirm = useCallback(() => {
    options?.onConfirm?.();
    visibleActions.off();
  }, [options, visibleActions]);

  const showConfirm = useCallback(
    (o: ConfirmOptions) => {
      setOptions(o);
      visibleActions.on();
    },
    [visibleActions],
  );

  return (
    <ConfirmContext.Provider value={showConfirm}>
      <ConfirmDialog
        visible={visible}
        title={options?.title}
        message={options?.message}
        confirmText={options?.confirmText}
        onClose={handleClose}
        onConfirm={handleConfirm}
      />
      {children}
    </ConfirmContext.Provider>
  );
};

export const useConfirm = () => {
  const contextValue = useContext(ConfirmContext);
  if (!contextValue) {
    throw new Error("useConfirm needs to be used inside a ConfirmProvider.");
  }
  return contextValue;
};

export default ConfirmProvider;
