import React from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import {
  Button,
  Icons,
  PasswordController,
  Text,
} from "@pushpress/shared-components";

import PageContainer from "../../../components/PageContainer";
import PageTitle from "../../../components/PageTitle";
import { useAnalytics } from "../../../components/AnalyticsProvider";
import {
  useGetLoggedInProfileLoginLazyQuery,
  useLoginMutation,
} from "./Authentication-generated.hooks";
import { useAppContext } from "../../../contexts/AppContext";
import { useToast } from "../../../components/ToastProvider";
import { saveUserToken } from "../../../api";
import { useToggle } from "../../../utils/hooks";
import AlternativeLoginModal from "../../../components/AlternativeLoginModal";

interface PasswordStepProps {
  email?: string;
  onLogin: (userUuid: string) => void;
  onBack: VoidFunction;
}

interface FormValues {
  password: string;
}

const PasswordStep = (props: PasswordStepProps) => {
  const { email, onLogin, onBack } = props;

  const { t } = useTranslation("common");
  const { setErrorToast } = useToast();
  const { client } = useAppContext();
  const analytics = useAnalytics();
  const [alternativeLoginVisible, alternativeLoginActions] = useToggle(false);
  const { control, handleSubmit } = useForm<FormValues>();

  const [login, { loading: loginLoading }] = useLoginMutation({
    onError() {
      analytics.trackEvent("password.fail", {}, true);
      setErrorToast({ message: t("login.failedMessage") });
    },
  });

  const [getProfile, { loading: profileLoading }] =
    useGetLoggedInProfileLoginLazyQuery({
      fetchPolicy: "no-cache",
      onError() {
        setErrorToast({ message: t("login.failedMessage") });
      },
    });

  const onLoginSuccess = async (token: string) => {
    alternativeLoginActions.off();
    saveUserToken(token);

    // get profile
    const profile = await getProfile();
    if (!profile) {
      return;
    }
    const userUuid = profile.data?.getLoggedInProfile.userUuid;

    onLogin(userUuid!);
  };

  const handleLogin = async (values: FormValues) => {
    // call login
    const result = await login({
      variables: {
        input: {
          clientUuid: client.uuid,
          email: email!,
          password: values.password,
        },
      },
    });
    if (!result.data) {
      return;
    }

    // save token on cookies
    const token = result.data.login.accessToken;
    // track success event
    analytics.trackEvent("password.success", {}, true);
    onLoginSuccess(token);
  };

  const loading = loginLoading || profileLoading;

  return (
    <>
      <PageContainer.RightPane>
        <Button
          buttonType="text"
          text={t("login.goBack")}
          type="button"
          size="small"
          icon={Icons.ArrowLeft}
          iconLocation="left"
          onClick={onBack}
          className="mb-2"
        />
        <PageTitle title={t("login.passwordTitle")} theme="light" />
        <form onSubmit={handleSubmit(handleLogin)}>
          <Text variant="body-lg" color="wolf-600">
            {email}
          </Text>
          <PasswordController
            control={control}
            name="password"
            required
            label={t("login.password")}
            className="mt-4"
          />
          <Button
            buttonType="text"
            text={t("login.forgotPassword")}
            type="button"
            size="medium"
            onClick={alternativeLoginActions.on}
          />
          <Button
            fullWidth
            buttonType="primary"
            size="large"
            text={t("login.login")}
            className="mt-4"
            type="submit"
            loading={loading}
          />
        </form>
      </PageContainer.RightPane>
      {email && (
        <AlternativeLoginModal
          visible={alternativeLoginVisible}
          closeModal={alternativeLoginActions.off}
          email={email}
          onLoginSuccess={onLoginSuccess}
        />
      )}
    </>
  );
};

export default PasswordStep;
