import React from "react";
import { Button, Modal, ButtonProps } from "@pushpress/shared-components";
import { useToggle } from "../../utils/hooks";
import Html from "../Html";

interface MoreInfoButtonProps extends Omit<ButtonProps<"button">, "ref"> {
  html: string;
  title: string;
}

const MoreInfoButton = (props: MoreInfoButtonProps) => {
  const { html, title, ...other } = props;

  const [moreInfoVisible, moreInfoActions] = useToggle(false);

  return (
    <>
      <Button
        type="button"
        buttonType="secondary"
        size="small"
        onClick={moreInfoActions.on}
        {...other}
      />
      <Modal visible={moreInfoVisible} closeModal={moreInfoActions.off}>
        <Modal.Card>
          <Modal.Header title={title} onClose={moreInfoActions.off} />
          <Modal.Content>
            <Html contentHtml={html} />
          </Modal.Content>
        </Modal.Card>
      </Modal>
    </>
  );
};

export default MoreInfoButton;
