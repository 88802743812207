import * as Types from "../../../graphql/graphql-generated.types";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type GetUserProfileStatusQueryVariables = Types.Exact<{
  input: Types.GetUserProfileStatusInput;
}>;

export type GetUserProfileStatusQuery = {
  __typename?: "Query";
  getUserProfileStatus: {
    __typename?: "UserProfileStatus";
    exists: boolean;
    hasProfileWithThisClient: boolean;
  };
};

export type GetLoggedInProfileLoginQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetLoggedInProfileLoginQuery = {
  __typename?: "Query";
  getLoggedInProfile: { __typename?: "Profile"; userUuid: string };
};

export type LoginMutationVariables = Types.Exact<{
  input: Types.LoginInput;
}>;

export type LoginMutation = {
  __typename?: "Mutation";
  login: {
    __typename?: "AuthSuccess";
    accessToken: string;
    refreshToken: string;
    clientUserUuid: string;
  };
};

export type LoginWithCodeMutationVariables = Types.Exact<{
  input: Types.LoginWithCodeInput;
}>;

export type LoginWithCodeMutation = {
  __typename?: "Mutation";
  loginWithCode: {
    __typename?: "AuthSuccess";
    accessToken: string;
    refreshToken: string;
    clientUserUuid: string;
  };
};

export type GenerateCodeLoginMutationVariables = Types.Exact<{
  input: Types.GenerateCodeLoginInput;
}>;

export type GenerateCodeLoginMutation = {
  __typename?: "Mutation";
  generateCodeLogin: { __typename?: "SuccessResponse"; success: boolean };
};

export type UpdateProfileMutationVariables = Types.Exact<{
  input: Types.UpdateProfileInput;
}>;

export type UpdateProfileMutation = {
  __typename?: "Mutation";
  updateProfile: { __typename?: "Profile"; userUuid: string };
};

export type ForgotPasswordMutationVariables = Types.Exact<{
  input: Types.ForgotPasswordInput;
}>;

export type ForgotPasswordMutation = {
  __typename?: "Mutation";
  forgotPassword: { __typename?: "SuccessResponse"; success: boolean };
};

export type CreateProfileMutationVariables = Types.Exact<{
  input: Types.CreateProfileInput;
}>;

export type CreateProfileMutation = {
  __typename?: "Mutation";
  createProfile: {
    __typename?: "Profile";
    userUuid: string;
    accessToken: string | null;
  };
};

export const GetUserProfileStatusDocument = gql`
  query GetUserProfileStatus($input: GetUserProfileStatusInput!) {
    getUserProfileStatus(getUserProfileStatusInput: $input) {
      exists
      hasProfileWithThisClient
    }
  }
`;

/**
 * __useGetUserProfileStatusQuery__
 *
 * To run a query within a React component, call `useGetUserProfileStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserProfileStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserProfileStatusQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUserProfileStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserProfileStatusQuery,
    GetUserProfileStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserProfileStatusQuery,
    GetUserProfileStatusQueryVariables
  >(GetUserProfileStatusDocument, options);
}
export function useGetUserProfileStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserProfileStatusQuery,
    GetUserProfileStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserProfileStatusQuery,
    GetUserProfileStatusQueryVariables
  >(GetUserProfileStatusDocument, options);
}
export type GetUserProfileStatusQueryHookResult = ReturnType<
  typeof useGetUserProfileStatusQuery
>;
export type GetUserProfileStatusLazyQueryHookResult = ReturnType<
  typeof useGetUserProfileStatusLazyQuery
>;
export type GetUserProfileStatusQueryResult = Apollo.QueryResult<
  GetUserProfileStatusQuery,
  GetUserProfileStatusQueryVariables
>;
export const GetLoggedInProfileLoginDocument = gql`
  query GetLoggedInProfileLogin {
    getLoggedInProfile {
      userUuid
    }
  }
`;

/**
 * __useGetLoggedInProfileLoginQuery__
 *
 * To run a query within a React component, call `useGetLoggedInProfileLoginQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoggedInProfileLoginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoggedInProfileLoginQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLoggedInProfileLoginQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetLoggedInProfileLoginQuery,
    GetLoggedInProfileLoginQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLoggedInProfileLoginQuery,
    GetLoggedInProfileLoginQueryVariables
  >(GetLoggedInProfileLoginDocument, options);
}
export function useGetLoggedInProfileLoginLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLoggedInProfileLoginQuery,
    GetLoggedInProfileLoginQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLoggedInProfileLoginQuery,
    GetLoggedInProfileLoginQueryVariables
  >(GetLoggedInProfileLoginDocument, options);
}
export type GetLoggedInProfileLoginQueryHookResult = ReturnType<
  typeof useGetLoggedInProfileLoginQuery
>;
export type GetLoggedInProfileLoginLazyQueryHookResult = ReturnType<
  typeof useGetLoggedInProfileLoginLazyQuery
>;
export type GetLoggedInProfileLoginQueryResult = Apollo.QueryResult<
  GetLoggedInProfileLoginQuery,
  GetLoggedInProfileLoginQueryVariables
>;
export const LoginDocument = gql`
  mutation Login($input: LoginInput!) {
    login(loginInput: $input) {
      accessToken
      refreshToken
      clientUserUuid
    }
  }
`;
export type LoginMutationFn = Apollo.MutationFunction<
  LoginMutation,
  LoginMutationVariables
>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginMutation,
    LoginMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(
    LoginDocument,
    options,
  );
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>;
export const LoginWithCodeDocument = gql`
  mutation LoginWithCode($input: LoginWithCodeInput!) {
    loginWithCode(loginWithCodeInput: $input) {
      accessToken
      refreshToken
      clientUserUuid
    }
  }
`;
export type LoginWithCodeMutationFn = Apollo.MutationFunction<
  LoginWithCodeMutation,
  LoginWithCodeMutationVariables
>;

/**
 * __useLoginWithCodeMutation__
 *
 * To run a mutation, you first call `useLoginWithCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginWithCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginWithCodeMutation, { data, loading, error }] = useLoginWithCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginWithCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginWithCodeMutation,
    LoginWithCodeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    LoginWithCodeMutation,
    LoginWithCodeMutationVariables
  >(LoginWithCodeDocument, options);
}
export type LoginWithCodeMutationHookResult = ReturnType<
  typeof useLoginWithCodeMutation
>;
export type LoginWithCodeMutationResult =
  Apollo.MutationResult<LoginWithCodeMutation>;
export type LoginWithCodeMutationOptions = Apollo.BaseMutationOptions<
  LoginWithCodeMutation,
  LoginWithCodeMutationVariables
>;
export const GenerateCodeLoginDocument = gql`
  mutation GenerateCodeLogin($input: GenerateCodeLoginInput!) {
    generateCodeLogin(generateCodeLoginInput: $input) {
      success
    }
  }
`;
export type GenerateCodeLoginMutationFn = Apollo.MutationFunction<
  GenerateCodeLoginMutation,
  GenerateCodeLoginMutationVariables
>;

/**
 * __useGenerateCodeLoginMutation__
 *
 * To run a mutation, you first call `useGenerateCodeLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateCodeLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateCodeLoginMutation, { data, loading, error }] = useGenerateCodeLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateCodeLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateCodeLoginMutation,
    GenerateCodeLoginMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateCodeLoginMutation,
    GenerateCodeLoginMutationVariables
  >(GenerateCodeLoginDocument, options);
}
export type GenerateCodeLoginMutationHookResult = ReturnType<
  typeof useGenerateCodeLoginMutation
>;
export type GenerateCodeLoginMutationResult =
  Apollo.MutationResult<GenerateCodeLoginMutation>;
export type GenerateCodeLoginMutationOptions = Apollo.BaseMutationOptions<
  GenerateCodeLoginMutation,
  GenerateCodeLoginMutationVariables
>;
export const UpdateProfileDocument = gql`
  mutation UpdateProfile($input: UpdateProfileInput!) {
    updateProfile(updateProfileInput: $input) {
      userUuid
    }
  }
`;
export type UpdateProfileMutationFn = Apollo.MutationFunction<
  UpdateProfileMutation,
  UpdateProfileMutationVariables
>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProfileMutation,
    UpdateProfileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProfileMutation,
    UpdateProfileMutationVariables
  >(UpdateProfileDocument, options);
}
export type UpdateProfileMutationHookResult = ReturnType<
  typeof useUpdateProfileMutation
>;
export type UpdateProfileMutationResult =
  Apollo.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<
  UpdateProfileMutation,
  UpdateProfileMutationVariables
>;
export const ForgotPasswordDocument = gql`
  mutation ForgotPassword($input: ForgotPasswordInput!) {
    forgotPassword(forgotPasswordInput: $input) {
      success
    }
  }
`;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<
  ForgotPasswordMutation,
  ForgotPasswordMutationVariables
>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useForgotPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ForgotPasswordMutation,
    ForgotPasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ForgotPasswordMutation,
    ForgotPasswordMutationVariables
  >(ForgotPasswordDocument, options);
}
export type ForgotPasswordMutationHookResult = ReturnType<
  typeof useForgotPasswordMutation
>;
export type ForgotPasswordMutationResult =
  Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<
  ForgotPasswordMutation,
  ForgotPasswordMutationVariables
>;
export const CreateProfileDocument = gql`
  mutation CreateProfile($input: CreateProfileInput!) {
    createProfile(createProfileInput: $input) {
      userUuid
      accessToken
    }
  }
`;
export type CreateProfileMutationFn = Apollo.MutationFunction<
  CreateProfileMutation,
  CreateProfileMutationVariables
>;

/**
 * __useCreateProfileMutation__
 *
 * To run a mutation, you first call `useCreateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProfileMutation, { data, loading, error }] = useCreateProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProfileMutation,
    CreateProfileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateProfileMutation,
    CreateProfileMutationVariables
  >(CreateProfileDocument, options);
}
export type CreateProfileMutationHookResult = ReturnType<
  typeof useCreateProfileMutation
>;
export type CreateProfileMutationResult =
  Apollo.MutationResult<CreateProfileMutation>;
export type CreateProfileMutationOptions = Apollo.BaseMutationOptions<
  CreateProfileMutation,
  CreateProfileMutationVariables
>;
