import React from "react";
import { useTranslation } from "react-i18next";
import moduleStyles from "./FullModal.module.scss";
import { multiStyles } from "../../utils";
import { IconButton, Modal, Icons } from "@pushpress/shared-components";

const S = multiStyles(moduleStyles);

interface FullModalProps {
  visible: boolean;
  blur?: boolean;
  isDocuments?: boolean;
  onClose?: () => void;
}

const FullModal: React.FC<FullModalProps> = (props) => {
  const {
    children,
    onClose,
    visible,
    isDocuments = false,
    blur = false,
  } = props;

  const { t } = useTranslation("common");

  return (
    <Modal
      visible={visible}
      closeModal={onClose}
      disableEscapeKeyDown
      disableContentPadding
      disableClickAway
      fullSize
    >
      <div className={S("root", [blur, "blur"])}>
        {onClose && (
          <div className={S("header")}>
            <div className={S("header-content")}>
              <IconButton
                icon={Icons.X}
                size={24}
                color="#000"
                onClick={onClose}
                aria-label={t("fullModal.close")}
              />
            </div>
          </div>
        )}
        <div className={S("body")}>
          <div className={S("body-content", [isDocuments, "documents"])}>
            {children}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default FullModal;
