export const addEllipsisIfHasMoreThanLengthLimit = (
  string: string,
  max: number,
): string => {
  return string.length > max ? string.substring(0, max) + "..." : string;
};

export const encodeToBase64 = (str: string) => {
  return typeof window !== "undefined"
    ? window.btoa(encodeURIComponent(str))
    : str;
};

export const decodeFromBase64 = (str: string) => {
  return typeof window !== "undefined"
    ? decodeURIComponent(window.atob(str))
    : str;
};

export function getInitials(...parts: (string | undefined | null)[]): string {
  return parts
    .filter(Boolean)
    .map((item) => item![0])
    .join("");
}

export function joinStrings(...parts: (string | undefined | null)[]): string {
  return parts.filter(Boolean).join(" ");
}
