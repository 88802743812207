import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button, PasswordController } from "@pushpress/shared-components";

interface ResetPasswordFormValues {
  newPassword: string;
  confirmPassword: string;
}

interface ResetPasswordFormProps {
  onSubmit: (password: string) => void;
  loading: boolean;
}

const ResetPasswordForm = (props: ResetPasswordFormProps) => {
  const { onSubmit, loading } = props;

  const { t } = useTranslation("common");

  const { control, handleSubmit } = useForm<ResetPasswordFormValues>({
    mode: "onTouched",
  });

  const resetPassword = (values: ResetPasswordFormValues) => {
    onSubmit(values.newPassword);
  };

  const newPassword = useWatch({
    control,
    name: "newPassword",
  });

  const validateConfirmPassword = (value: string) => {
    if (value !== newPassword) {
      return "The password does not match";
    }
  };

  return (
    <form noValidate onSubmit={handleSubmit(resetPassword)}>
      <PasswordController
        label={t("resetPassword.newPassword")}
        control={control}
        name="newPassword"
        required
        inputProps={{
          name: "new-password",
          autoComplete: "off",
        }}
      />
      <PasswordController
        label={t("resetPassword.confirmPassword")}
        control={control}
        name="confirmPassword"
        required
        validate={validateConfirmPassword}
        inputProps={{
          name: "confirm-password",
          autoComplete: "off",
        }}
      />
      <Button
        className="mt-3"
        buttonType="secondary"
        size="large"
        text={t("resetPassword.submit")}
        type="submit"
        fullWidth
        loading={loading}
      />
    </form>
  );
};

export default ResetPasswordForm;
