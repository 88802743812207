import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Grid, Text, ModalProps } from "@pushpress/shared-components";
import PanelModal from "../PanelModal";
import AlternativeLogin from "../AlternativeLogin";
import { useAppContext } from "../../contexts/AppContext";
import OTPCodeCheck, { OTPCodeCheckForm } from "../OTPCodeCheck";
import { useForm } from "react-hook-form";
import {
  useGenerateCodeLoginMutation,
  useLoginMutation,
  useLoginWithCodeMutation,
} from "../../screens/auth/Authentication/Authentication-generated.hooks";
import { useAnalytics } from "../AnalyticsProvider";
import OTPResetPassword, { OTPResetPasswordForm } from "../OTPResetPassword";
import { useUpdatePasswordMutation } from "../../screens/signin/SignInForm/SignIn-generated.hooks";
import { useTranslation } from "react-i18next";
import SuccessUpdatingPassword from "../OTPResetPassword/SuccessUpdatingPassword";

interface AlternativeLoginModalProps extends ModalProps {
  email: string;
  onLoginSuccess: (accessToken: string) => void;
}

const AlternativeLoginModal = (props: AlternativeLoginModalProps) => {
  const { client } = useAppContext();

  return (
    <PanelModal {...props} title={""} disableClickAway disableEscapeKeyDown>
      <Grid container item justifyContent="center" alignItems="center">
        <img src={client.logoUrl} width={"50px"} className="mb-1" />
      </Grid>
      {props.visible && <AlternativeLoginModalContent {...props} />}
    </PanelModal>
  );
};

type AlternativeLoginStep =
  | "alternativeLogin"
  | "resetPassword"
  | "otpLogin"
  | "successUpdatingPassword";

const AlternativeLoginModalContent = ({
  email,
  onLoginSuccess,
}: AlternativeLoginModalProps) => {
  const { t } = useTranslation("signin");
  const analytics = useAnalytics();
  const {
    client: { uuid: clientUuid, company, subdomain },
  } = useAppContext();
  const [accessToken, setAccessToken] = useState<string>();
  const [step, setStep] = useState<AlternativeLoginStep>("alternativeLogin");
  const [generateLoginCode] = useGenerateCodeLoginMutation();
  const [loginMutation, { loading: loadingLoginAfterResettingPassword }] =
    useLoginMutation();
  const [updatePasswordMutation, { loading: loadingUpdatingPassword }] =
    useUpdatePasswordMutation();

  const {
    control: otpCodeCheckForm,
    handleSubmit: handleSubmitOTPCodeCheckForm,
    setError: setOTPCodeCheckError,
  } = useForm<OTPCodeCheckForm>();
  const {
    control: resetPasswordForm,
    handleSubmit: handleResetPasswordForm,
    setError: setResetPasswordError,
  } = useForm<OTPResetPasswordForm>();

  const analyticsProperties = useMemo(() => {
    return {
      clientUuid,
      company,
      subdomain,
    };
  }, [clientUuid, company, subdomain]);

  const trackAnalyticsEvent = useCallback(
    (event: string) => {
      analytics.trackEvent(`login.${event}`, analyticsProperties, true);
    },
    [analytics, analyticsProperties],
  );

  const goToStep = useCallback(
    (stepName: AlternativeLoginStep) => {
      trackAnalyticsEvent(`${stepName}.started`);
      setStep(stepName);
    },
    [setStep, trackAnalyticsEvent],
  );

  useEffect(() => {
    trackAnalyticsEvent("alternativeLogin.started");
  }, [trackAnalyticsEvent]);

  const [loginWithCode, { loading: loadingLoginWithCode }] =
    useLoginWithCodeMutation({
      onError() {
        trackAnalyticsEvent("code.fail");
        setOTPCodeCheckError("code", {
          message: t("form.errors.invalidCode"),
        });
      },
    });

  const generateOTPCode = () => {
    generateLoginCode({
      variables: {
        input: {
          clientUuid,
          email,
        },
      },
    });
  };

  const submitOtpCodeCheckForm = useCallback(
    async (values: OTPCodeCheckForm) => {
      const { data } = await loginWithCode({
        variables: {
          input: {
            email,
            code: values.code,
            clientUuid,
          },
        },
      });

      if (data?.loginWithCode.accessToken) {
        trackAnalyticsEvent("code.success");
        onLoginSuccess(data?.loginWithCode.accessToken);
      }
    },
    [clientUuid, loginWithCode, email, onLoginSuccess, trackAnalyticsEvent],
  );

  const submitResetPasswordForm = useCallback(
    async (values: OTPResetPasswordForm) => {
      const updatePasswordResponse = await updatePasswordMutation({
        variables: {
          input: {
            email,
            code: values.code,
            newPassword: values.newPassword,
            clientUuid: undefined,
            currentPassword: undefined,
          },
        },
        context: {
          headers: {
            Authorization: undefined,
          },
        },
      });

      const success = updatePasswordResponse.data?.updatePassword;

      if (!success) {
        trackAnalyticsEvent("resetPasswordCode.fail");
        setResetPasswordError("code", {
          message: t("form.errors.invalidCode"),
        });
        return;
      }

      trackAnalyticsEvent("resetPasswordCode.success");
      const response = await loginMutation({
        variables: {
          input: {
            clientUuid,
            email,
            password: values.newPassword,
          },
        },
      });

      if (response.data?.login.accessToken) {
        setAccessToken(response.data?.login.accessToken);
      }

      goToStep("successUpdatingPassword");
    },
    [
      email,
      updatePasswordMutation,
      setResetPasswordError,
      loginMutation,
      t,
      trackAnalyticsEvent,
      clientUuid,
      goToStep,
    ],
  );

  const contentMap: { [key in AlternativeLoginStep]: ReactNode } = {
    alternativeLogin: (
      <div className="mt-2">
        <AlternativeLogin
          goToOTPLogin={() => {
            generateOTPCode();
            goToStep("otpLogin");
          }}
          goToResetPassword={() => {
            generateOTPCode();
            goToStep("resetPassword");
          }}
        />
      </div>
    ),
    resetPassword: (
      <div className="mt-2">
        <OTPResetPassword
          control={resetPasswordForm}
          email={email}
          handleSubmit={handleResetPasswordForm(submitResetPasswordForm)}
          loading={loadingUpdatingPassword}
        />
      </div>
    ),
    successUpdatingPassword: (
      <>
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <Grid item>
            <Text variant="heading-4">
              {t("successUpdatingPassword.title")}
            </Text>
          </Grid>
          <Grid item>
            <Text variant="body-lg" className="mb-2" align="center">
              {t("successUpdatingPassword.description")}
            </Text>
          </Grid>
          <SuccessUpdatingPassword
            goToAccount={() => accessToken && onLoginSuccess(accessToken)}
          />
        </Grid>
      </>
    ),
    otpLogin: (
      <div className="mt-2">
        <OTPCodeCheck
          control={otpCodeCheckForm}
          email={email}
          handleSubmit={handleSubmitOTPCodeCheckForm(submitOtpCodeCheckForm)}
          loading={loadingLoginWithCode || loadingLoginAfterResettingPassword}
        />
      </div>
    ),
  };

  return <>{contentMap[step]}</>;
};

export default AlternativeLoginModal;
