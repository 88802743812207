import { createContext, useContext } from "react";
import {
  AppointmentTypeFragment,
  AppointmentStaffOptionFragment,
  AppointmentInvoicePreviewFragment,
} from "../pages/appointments/[apptPkgId]/AppointmentsPage-generated.hooks";

export interface AppointmentContextState {
  appointmentPackage: AppointmentPackage;
  appointmentType?: AppointmentTypeFragment;
  invoicePreview?: AppointmentInvoicePreviewFragment;
  transactionFee?: NonNullable<
    AppointmentInvoicePreviewFragment["fees"]
  >[number];
  participant?: Participant;
  sessionCreditsPurchased: { [key: UserId]: number };
  scheduledSessions: { [key: UserId]: ScheduledSession[] };
  skippedBooking: boolean;
  participantHasAvailableCredits: boolean;
  paymentMethodId?: string;
  staff: AppointmentStaffOptionFragment[];
  isLinkedAccount: boolean;
  apptInvalidMessage?: {
    title?: string;
    message?: string;
  };
  amountDue: number;
  amountTax: number;
  percentageTax: number;
  discount: number;
  discountPercent: number;
  isFree: boolean;
  isLoading: boolean;
  taxName?: string;
  previousScreen?: AppointmentScreen;
  participantType?: ParticipantType;
  participantTypeVisible: boolean;
  paymentSuccessVisible: boolean;
  bookingSuccessVisible: boolean;
  documentsSuccessVisible: boolean;
  paying: boolean;
  nextPaymentScreen: "booking" | "documents";
  setParticipantType: (type: ParticipantType) => void;
  changeParticipant: (participant?: Participant) => void;
  navigateToScreen: (screen: AppointmentScreen) => void;
  setSkippedBooking: (value: boolean) => void;
  setPaymentMethodId: (id: string) => void;
  addParticipantSession: (session: ScheduledSession) => void;
  signUpAdditionalPerson: () => void;
  purchasePackage: (
    userUuid: string,
    paymentId: string,
    paymentType: PaymentMethodType | "free",
  ) => Promise<void>;
  paymentSuccessActions: UseToggle;
  bookingSuccessActions: UseToggle;
  documentsSuccessActions: UseToggle;
  goToAfterPayment: VoidFunction;
}

const AppointmentContext = createContext<AppointmentContextState | undefined>(
  undefined,
);

export const useAppointmentContext = (): AppointmentContextState => {
  const contextValue = useContext(AppointmentContext);
  if (!contextValue) {
    throw new Error(
      "useAppointmentContext must be used inside AppointmentContext",
    );
  }
  return contextValue;
};

export default AppointmentContext;
