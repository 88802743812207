import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation } from "react-router-dom";

import { useAppContext } from "../../../contexts/AppContext";
import EmailStep from "./EmailStep";
import ProfileStep from "./ProfileStep";
import PasswordStep from "./PasswordStep";
import { useGetUserProfileStatusLazyQuery } from "./Authentication-generated.hooks";
import { useAnalytics } from "../../../components/AnalyticsProvider";

type LoginStep = "email" | "password" | "profile";

interface AuthenticationContentProps {
  onSuccess: (token: string) => void;
}

const AuthenticationContent = (props: AuthenticationContentProps) => {
  const { onSuccess } = props;

  // hooks

  const location = useLocation();
  const analytics = useAnalytics();
  const {
    client: { uuid: clientUuid, company, subdomain },
  } = useAppContext();
  const locationState = location.state as { email?: string } | null;

  // states

  const [step, setStep] = useState<LoginStep>("email");
  const [loginEmail, setLoginEmail] = useState<string | undefined>(
    locationState?.email,
  );
  const [userUuid, setUserUuid] = useState<string>();

  const analyticsProperties = useMemo(() => {
    return {
      clientUuid,
      company,
      subdomain,
    };
  }, [clientUuid, company, subdomain]);

  const trackAnalyticsEvent = useCallback(
    (event: string) => {
      analytics.trackEvent(`login.${event}`, analyticsProperties, true);
    },
    [analytics, analyticsProperties],
  );

  const goToStep = useCallback(
    (stepName: LoginStep) => {
      trackAnalyticsEvent(`${stepName}.started`);
      setStep(stepName);
    },
    [setStep, trackAnalyticsEvent],
  );

  useEffect(() => {
    trackAnalyticsEvent("email.started");
  }, [trackAnalyticsEvent]);

  // graphql

  const [getUserStatus, { loading, data: userStatus }] =
    useGetUserProfileStatusLazyQuery({
      fetchPolicy: "no-cache",
    });
  const hasProfileWithThisClient =
    !!userStatus?.getUserProfileStatus?.hasProfileWithThisClient;
  const userExists = !!userStatus?.getUserProfileStatus.exists;

  // callbacks

  const handleContinueEmail = async (email: string) => {
    const result = await getUserStatus({
      variables: {
        input: {
          clientUuid,
          email: email,
        },
      },
    });
    setLoginEmail(email);
    const userExists = result.data?.getUserProfileStatus.exists;
    if (userExists) {
      goToStep("password");
    } else {
      goToStep("profile");
    }
  };

  const handleLoginSuccess = async (uuid: string) => {
    if (hasProfileWithThisClient) {
      onSuccess(uuid);
    } else {
      setUserUuid(uuid);
      goToStep("profile");
    }
  };

  const handleUpdateAccount = () => {
    onSuccess(userUuid!);
  };

  const handleCreateAccount = (uuid: string) => {
    onSuccess(uuid);
  };

  const handleBack = () => {
    goToStep("email");
  };

  // effects

  /**
   * Get the user status to show the profile page after the password if it does not have an account in the gym
   * show the password step when it comes from the reset password
   */
  useEffect(() => {
    const locationEmail = locationState?.email;
    if (!locationEmail) {
      return;
    }
    (async () => {
      await getUserStatus({
        variables: {
          input: {
            clientUuid,
            email: locationEmail,
          },
        },
      });
      goToStep("password");
    })();
  }, [clientUuid, getUserStatus, locationState?.email, goToStep]);

  // content by step

  const contentMap: { [key in LoginStep]: ReactNode } = {
    email: (
      <EmailStep
        loading={loading}
        email={loginEmail}
        onContinue={handleContinueEmail}
      />
    ),
    password: (
      <PasswordStep
        email={loginEmail}
        onLogin={handleLoginSuccess}
        onBack={handleBack}
      />
    ),
    profile: (
      <ProfileStep
        email={loginEmail}
        userExists={userExists}
        userUuid={userUuid}
        onCreateAccount={handleCreateAccount}
        onUpdateAccount={handleUpdateAccount}
        onBack={handleBack}
      />
    ),
  };

  return <>{contentMap[step]}</>;
};

export default AuthenticationContent;
