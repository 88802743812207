import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@pushpress/shared-components";
import SuccessDialog from "../SuccessDialog";

export interface DocumentsSuccessDialogProps {
  visible: boolean;
  nextScreen: "booking" | "confirmation";
  onNextClick: VoidFunction;
}

const DocumentsSuccessDialog: React.FC<DocumentsSuccessDialogProps> = (
  props,
) => {
  const { visible, nextScreen, onNextClick } = props;
  const { t } = useTranslation("common");
  const nextText = t(`documentsSuccessDialog.${nextScreen}`);

  return (
    <SuccessDialog
      visible={visible}
      title={t("documentsSuccessDialog.documentsSigned")}
      actions={
        <Button
          text={nextText}
          buttonType="primary"
          fullWidth
          size="large"
          onClick={onNextClick}
        />
      }
    />
  );
};

export default DocumentsSuccessDialog;
