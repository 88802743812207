import {
  CreateProfileInput,
  UpdateProfileInput,
} from "../../../graphql/graphql-generated.types";

export const getDefaultFormData = (query?: {
  [key: string]: string;
}): Partial<NewAccount> => {
  if (query) {
    return {
      firstName: (query.first_name as string) || "",
      lastName: (query.last_name as string) || "",
      email: (query.email as string) || "",
      phone: (query.phone as string) || "",
    };
  }
  return defaultFormState;
};

export const fixAccountValues = (values: NewAccount) => {
  const phone = removePlusSign(values.phone);
  return {
    ...values,
    phone,
  };
};

export const removePlusSign = (text?: string) => {
  if (!text || typeof text !== "string") {
    return;
  }
  return text.replaceAll("+", "");
};

const defaultFormState = {
  firstName: "",
  lastName: "",
  dateOfBirth: "",
  email: "",
  phone: "",
  password: "",
};

export interface ProfileForm {
  firstName: string;
  lastName: string;
  birthday: string;
  phone: string;
  marketingConsent: boolean;
  privacyConsent: boolean;
}

export const convertToUpdateProfileInput = (
  values: ProfileForm,
  userUuid?: string,
) => {
  return {
    userUuid: userUuid!,
    firstName: values.firstName,
    lastName: values.lastName,
    phoneNumber: values.phone,
    birthday: values.birthday,
    marketingConsent: values.marketingConsent,
    privacyConsent: values.privacyConsent,
  } as UpdateProfileInput;
};

export const convertToCreateProfileInput = (
  values: ProfileForm,
  clientUuid: string,
  email: string,
) => {
  return {
    clientUuid,
    email,
    firstName: values.firstName,
    lastName: values.lastName,
    phoneNumber: values.phone,
    birthday: values.birthday,
    source: "LANDING_PAGE",
    marketingConsent: values.marketingConsent,
    privacyConsent: values.privacyConsent,
  } as CreateProfileInput;
};
